<template>
    <div id="display-json-object-to-list-component">
        <v-list dense rounded>
            <template v-for="key in Object.keys(json)">
                <div :key="key">
                    <template v-if="json[key] && typeof json[key] === 'object'">
                        <v-list-group collapse-icon="mdi-minus" expand-icon="mdi-plus">
                            <template v-slot:activator="{ props }">
                                <v-list-item class="p-0" dense rounded v-bind="props">
                                    <div :style="`margin-left: ${depth * 12}px`">
                                        <p class="font-weight-bold text-uppercase text-skin-default">{{ key }}</p>
                                    </div>
                                </v-list-item>
                            </template>

                            <display-json-object-to-list-component :depth="depth + 1" :json="json[key]" />
                        </v-list-group>
                    </template>

                    <template v-else>
                        <v-list-item class="p-0 my-n1 font-14" dense rounded>
                            <div :style="`margin-left: ${depth * 12}px`">
                                <span class="mr-1 font-weight-medium text-skin-default"> {{ key }}: </span>
                                <span class="text-skin-default">{{ json[key] }}</span>
                            </div>
                        </v-list-item>
                    </template>
                </div>
            </template>
        </v-list>
    </div>
</template>

<script>
export default {
    name: 'DisplayJsonObjectToListComponent',
    props: {
        json: {
            type: Object,
            required: true,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },
}
</script>
