<template>
    <div id="list-check-network">
        <v-expand-transition>
            <div v-if="is_checking_network">
                <span class="font-weight-medium font-14">
                    <v-icon class="pr-2">mdi-upload-network</v-icon>
                    {{ $t('list_check_network.last_10_device_messages') }}
                </span>

                <div v-if="historical_traited_data.length > 0" class="font-14">
                    <v-list class="mt-2 px-2" dense rounded>
                        <div v-for="(network_data, index) in historical_traited_data" class="mt-3">
                            <!-- REVOKE DTD -->
                            <v-list-item>
                                <v-list-item-content />

                                <span class="error--text font-12 pointer" @click="openRevokeDeviceTraitedDataDialog(network_data)">
                                    {{ $t('list_check_network.delete_statement') }}
                                </span>
                            </v-list-item>
                            <!-- DATE RELEVE -->
                            <v-list-item>
                                <v-list-item-content class="font-weight-medium pa-0">
                                    {{ $t('list_check_network.last_statement') }}
                                </v-list-item-content>

                                <div class="text-end mb-4">
                                    <chips-last-statement :danger="345600" :date="network_data.due_date.date" :warning="172800" />
                                    <br />
                                    <span class="font-12">{{ network_data.due_date.date | moment('DD/MM/Y HH:mm:ss') }}</span>
                                </div>
                            </v-list-item>

                            <!-- ASSET METRICS -->
                            <v-list-group
                                v-if="headers.filter((item) => item.type === 'asset').length > 0"
                                :value="true"
                                prepend-icon="mdi-storage-tank-outline"
                            >
                                <template v-slot:activator>
                                    <v-list-item-content class="font-weight-medium pa-0">
                                        {{ $t('list_check_network.equipment_data') }}
                                    </v-list-item-content>
                                </template>

                                <template v-for="header in headers.filter((item) => item.type === 'asset')">
                                    <v-list-item :key="header.key" class="pl-6">
                                        <v-list-item-content class="font-weight-medium pa-0">
                                            {{ header.title }}
                                        </v-list-item-content>

                                        {{ displayToHumanAssetMetricFromCompleteDTd(network_data, header.key) }}
                                    </v-list-item>
                                </template>
                            </v-list-group>

                            <v-list class="pa-0" rounded>
                                <v-list-group :value="true" prepend-icon="mdi-access-point-network">
                                    <template v-slot:activator>
                                        <v-list-item-content class="font-weight-medium pa-0">
                                            {{ $t('list_check_network.sensor_data') }}
                                        </v-list-item-content>
                                    </template>

                                    <!-- DEVICE METRICS -->
                                    <template v-if="headers.filter((item) => item.type === 'asset').length === 0">
                                        <template
                                            v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'all')"
                                        >
                                            <v-list v-if="header.key === 'data'" rounded>
                                                <v-list-item>
                                                    <v-list-item-content class="font-weight-medium pa-0">
                                                        {{ $t('list_check_network.raw_data') }}
                                                    </v-list-item-content>

                                                    <div class="pl-6 text-skin-default w-80">
                                                        {{ JSON.parse(network_data['data']) }}
                                                    </div>
                                                </v-list-item>

                                                <v-list-group>
                                                    <template v-slot:activator>
                                                        <v-list-item-content class="font-weight-medium pa-0">
                                                            {{ $t('list_check_network.formatted_raw_data') }}
                                                        </v-list-item-content>
                                                    </template>
                                                    <div class="pl-4">
                                                        <display-json-object-to-list-component :json="JSON.parse(network_data['data'])" />
                                                    </div>
                                                </v-list-group>
                                            </v-list>

                                            <v-list-item
                                                v-else-if="!isPnes() || (isPnes() && header.key !== 'payload')"
                                                :key="header.key"
                                                class="pl-6"
                                            >
                                                <v-list-item-content class="font-weight-medium pa-0">
                                                    {{ $t(header.title) }}
                                                </v-list-item-content>

                                                <template v-if="header.key === 'erreur'">
                                                    {{ displayToHumanError(network_data.erreur, network_data.hauteur_liquide) }}
                                                </template>

                                                <template v-else-if="header.key === 'hauteur_liquide'">
                                                    {{
                                                        !(network_data.hauteur_liquide < 0) && network_data.hauteur_liquide !== undefined
                                                            ? calculatedMeasure(network_data.hauteur_liquide)
                                                            : '/'
                                                    }}
                                                </template>

                                                <template v-else-if="header.key.includes('data.')">
                                                    {{ JSON.parse(network_data['data'])[header.key.split('.')[1]] }}
                                                </template>

                                                <template v-else>
                                                    {{ network_data[header.key] ? network_data[header.key] : '-' }}
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </template>

                                    <!-- REQUIRED METRICS -->
                                    <template
                                        v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'required')"
                                    >
                                        <v-list-item
                                            v-if="!isPnes || !isCR100((isPnes || isCR100) && header.key !== 'batterie')"
                                            :key="header.key"
                                            class="pl-6"
                                        >
                                            <v-list-item-content class="font-weight-medium pa-0">
                                                {{ header.title }}
                                            </v-list-item-content>

                                            <template v-if="header.key === 'declencheur'">
                                                {{ displayToHumanTrigger(network_data.declencheur) }}
                                            </template>

                                            <template v-else-if="header.key === 'quality_network'">
                                                <div v-if="(!isPnes || isCR100) && network_data.quality_network === null">
                                                    <v-icon class="network-icon" color="disabled"> mdi-network-strength-4-cog </v-icon>
                                                    {{ $t('list_check_network.no_data') }}
                                                </div>
                                                <div v-else-if="getSignalQuality(network_data) === 0">
                                                    <v-icon class="network-icon" color="error"> mdi-network-strength-off-outline </v-icon>
                                                    {{ $t('list_check_network.bad') }}
                                                </div>
                                                <div v-else-if="getSignalQuality(network_data) === 1">
                                                    <v-icon class="network-icon" color="error"> mdi-network-strength-1-alert </v-icon>
                                                    {{ $t('list_check_network.bad') }}
                                                </div>
                                                <div v-else-if="getSignalQuality(network_data) === 2">
                                                    <v-icon class="network-icon" color="error"> mdi-network-strength-2-alert </v-icon>
                                                    {{ $t('list_check_network.medium') }}
                                                </div>
                                                <div v-else-if="getSignalQuality(network_data) === 3">
                                                    <v-icon class="network-icon" color="warning"> mdi-network-strength-2 </v-icon>
                                                    {{ $t('list_check_network.medium') }}
                                                </div>
                                                <div v-else-if="getSignalQuality(network_data) === 4">
                                                    <v-icon class="network-icon" color="green">mdi-network-strength-3 </v-icon>
                                                    {{ $t('list_check_network.good') }}
                                                </div>
                                                <div v-else-if="getSignalQuality(network_data) === 5">
                                                    <v-icon class="network-icon" color="green">mdi-network-strength-4 </v-icon>
                                                    {{ $t('list_check_network.very_good') }}
                                                </div>
                                            </template>
                                            <template v-else>
                                                {{ network_data[header.key] ? network_data[header.key] : '-' }}
                                            </template>
                                        </v-list-item>
                                    </template>

                                    <!-- OPTIONAL DATA (PNES/CR100) -->
                                    <template
                                        v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'optional')"
                                    >
                                        <template v-if="isPnes() || isCR100()">
                                            <!-- <v-list-item :key="header.key"> -->

                                            <ListItemRow
                                                v-if="header.key === 'datePnes' && isPnes()"
                                                :mkey="header.key"
                                                :title="header.title"
                                                :value="getDateMessagePnes(network_data)"
                                            />
                                            <ListItemRow
                                                v-if="header.key === 'volumePnes' && isPnes()"
                                                :mkey="header.key"
                                                :show-null="false"
                                                :title="header.title"
                                                :value="getLastVolumePnes(network_data)"
                                            />
                                            <ListItemRow
                                                v-if="header.key === 'calendar'"
                                                :mkey="header.key"
                                                :title="header.title"
                                                :value="getPnesCalendar()"
                                            />
                                            <ListItemRow
                                                v-if="header.key === 'deviceRepeat'"
                                                :mkey="header.key"
                                                :title="header.title"
                                                value=""
                                            >
                                                <div class="d-block mt-8 mb-8">
                                                    <template v-for="relatedDevice in getRelatedDevices()">
                                                        <li>
                                                            <a
                                                                :href="
                                                                    'https://dashboard.fourdata.io/adminDevices/adminDevicesDetails?c=' +
                                                                    relatedDevice.id_device
                                                                "
                                                            >
                                                                {{ relatedDevice.device_number }}</a
                                                            >
                                                        </li>
                                                    </template>
                                                </div>
                                            </ListItemRow>
                                        </template>
                                    </template>
                                </v-list-group>
                            </v-list>

                            <!-- ADDITIONAL DATA -->
                            <v-list class="pa-0" rounded>
                                <v-list-group prepend-icon="mdi-magnify-plus-outline">
                                    <template v-slot:activator>
                                        <v-list-item-content class="font-weight-medium pa-0">
                                            {{ $t('list_check_network.additional_data') }}
                                        </v-list-item-content>
                                    </template>

                                    <template v-if="headers.filter((item) => item.type === 'asset').length > 0">
                                        <!-- DEVICE METRICS -->
                                        <template
                                            v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'all')"
                                        >
                                            <v-list v-if="header.key === 'data'" rounded>
                                                <v-list-item>
                                                    <v-list-item-content class="font-weight-medium pa-0">
                                                        {{ $t('list_check_network.raw_data') }}
                                                    </v-list-item-content>
                                                    <div class="pl-6 text-skin-default w-80">
                                                        {{ JSON.parse(network_data['data']) }}
                                                    </div>
                                                </v-list-item>

                                                <v-list-group>
                                                    <template v-slot:activator>
                                                        <v-list-item-content class="font-weight-medium pa-0">
                                                            {{ $t('list_check_network.formatted_raw_data') }}
                                                        </v-list-item-content>
                                                    </template>
                                                    <div class="pl-4">
                                                        <display-json-object-to-list-component :json="JSON.parse(network_data['data'])" />
                                                    </div>
                                                </v-list-group>
                                            </v-list>

                                            <v-list-item v-else :key="header.key" class="pl-6">
                                                <v-list-item-content class="font-weight-medium pa-0">
                                                    {{ header.title }}
                                                </v-list-item-content>

                                                <template v-if="header.key === 'erreur'">
                                                    {{ displayToHumanError(network_data.erreur, network_data.hauteur_liquide) }}
                                                </template>

                                                <template v-else-if="header.key === 'hauteur_liquide'">
                                                    {{
                                                        !(network_data.hauteur_liquide < 0) && network_data.hauteur_liquide !== undefined
                                                            ? calculatedMeasure(network_data.hauteur_liquide)
                                                            : '/'
                                                    }}
                                                </template>

                                                <template v-else-if="header.key.includes('data.')">
                                                    {{ JSON.parse(network_data['data'])[header.key.split('.')[1]] }}
                                                </template>

                                                <template v-else>
                                                    {{ network_data[header.key] ? network_data[header.key] : '-' }}
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </template>

                                    <!-- ADMIN METRICS -->
                                    <template
                                        v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'admin')"
                                    >
                                        <v-list v-if="header.key === 'data'" rounded>
                                            <v-list-item>
                                                <v-list-item-content class="font-weight-medium pa-0">
                                                    {{ $t('list_check_network.raw_data') }}
                                                </v-list-item-content>
                                                <div class="pl-6 text-skin-default w-80">
                                                    {{ JSON.parse(network_data['data']) }}
                                                </div>
                                            </v-list-item>

                                            <v-list-group>
                                                <template v-slot:activator>
                                                    <v-list-item-content class="font-weight-medium pa-0">
                                                        {{ $t('list_check_network.formatted_raw_data') }}
                                                    </v-list-item-content>
                                                </template>
                                                <div class="pl-4">
                                                    <display-json-object-to-list-component :json="JSON.parse(network_data['data'])" />
                                                </div>
                                            </v-list-group>
                                        </v-list>

                                        <v-list-item v-else :key="header.key" class="pl-6">
                                            <v-list-item-content class="font-weight-medium pa-0">
                                                {{ header.title }}
                                            </v-list-item-content>

                                            {{ network_data[header.key] ? network_data[header.key] : '-' }}
                                        </v-list-item>
                                    </template>
                                </v-list-group>
                            </v-list>

                            <v-divider v-if="index !== historical_traited_data.length - 1" class="mt-3" />
                        </div>
                    </v-list>
                </div>

                <div v-else class="font-14">
                    <v-list class="mt-2 text-center" dense>
                        {{ $t('list_check_network.no_data') }}
                    </v-list>
                </div>
            </div>
        </v-expand-transition>

        <revoke-device-traited-data-dialog ref="RevokeDeviceTraitedDataDialogComponent" />
    </div>
</template>

<script>
import ChipsLastStatement from './ChipsLastStatement'
import moment from 'moment'
import RevokeDeviceTraitedDataDialog from '@/components/RevokeDeviceTraitedDataDialogComponent.vue'
import ListItemRow from '@/components/ListItemRow.vue'
import DisplayJsonObjectToListComponent from '@/components/global/containement/DisplayJsonObjectToListComponent.vue'

export default {
    name: 'ListCheckNetwork',
    components: {
        DisplayJsonObjectToListComponent,
        ListItemRow,
        RevokeDeviceTraitedDataDialog,
        ChipsLastStatement,
    },
    props: {
        historical_traited_data: {
            type: Array,
            required: true,
        },
        is_checking_network: {
            type: Boolean,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        device: {
            type: Object,
            required: true,
        },
        details_asset: {
            type: Object,
            default: {},
            required: false,
        },
    },
    methods: {
        moment() {
            return moment
        },
        parseData(data) {
            return JSON.parse(data)
        },
        isPnes() {
            const idDeviceReferentialPnes = [152, 218]
            return idDeviceReferentialPnes.includes(this.device.device_referentiel.id_device_referentiel)
        },
        isCR100() {
            const idDeviceReferentialPnes = [151, 217]
            return idDeviceReferentialPnes.includes(this.device.device_referentiel.id_device_referentiel)
        },
        getDateMessagePnes(networkData) {
            if (
                this.parseData(networkData.data).hasOwnProperty('message') &&
                this.parseData(networkData.data).message.hasOwnProperty('cr_date')
            ) {
                return moment(this.parseData(networkData.data).message.cr_date.date).format('DD/MM/Y HH:mm:ss')
            }
            return '-'
        },
        getSignalQuality(networkData) {
            return this.isPnes()
                ? this.regleDeTrois(this.parseData(networkData.data).message.content.signalQuality, 0, 31)
                : networkData.quality_network
        },
        getPnesCalendar() {
            let calendar = '-'
            if (this.isPnes() && this.details_asset.configuration !== undefined) {
                const configuration = JSON.parse(this.details_asset.configuration)
                if (configuration && configuration.hasOwnProperty('pnes') && configuration.pnes.hasOwnProperty('nameConfigurationWeekly')) {
                    calendar = configuration.pnes.nameConfigurationWeekly
                }
            }

            return calendar
        },
        getLastVolumePnes(networkData) {
            const data = this.parseData(networkData.data)
            if (data.hasOwnProperty('message')) {
                const message = data.message
                if (
                    message.hasOwnProperty('content') &&
                    message.content.hasOwnProperty('data') &&
                    message.content.data.hasOwnProperty('data')
                ) {
                    return message.content.data.data.volume_percent + ' %'
                }
                if (message.hasOwnProperty('content') && message.content.hasOwnProperty('frames')) {
                    let res = ''
                    message.content.frames.forEach((content) => {
                        if (content.hasOwnProperty('measures')) {
                            let volumePercent = content.measures.measures[0].data.volume_percent
                            let pressure = content.measures.measures[0].data.pressure
                            res +=
                                content.module.numDevice + ' : ' + (volumePercent === undefined ? pressure + ' hPa ' : volumePercent + '% ')
                        }
                    })
                    return res
                }
            }

            return '-'
        },
        getRelatedDevices() {
            let devices = []
            if (65 in this.device.relations) {
                devices = this.device.relations[65].devices
            } else if (66 in this.device.relations) {
                devices = this.device.relations[66].devices
            }

            return devices
        },
        regleDeTrois(value, minInput, maxInput, minTo = 0, maxTo = 5, precision = 0) {
            value = Math.max(value, minInput)
            value = Math.min(value, maxInput)
            const percent = (value - minInput) / (maxInput - minInput)

            return Math.round(percent * (maxTo - minTo) + minTo, precision)
        },
        calculatedMeasure(measure) {
            return measure < 0 ? 0 : Math.round(measure)
        },
        openRevokeDeviceTraitedDataDialog(device_traited_data) {
            this.$refs.RevokeDeviceTraitedDataDialogComponent.openRevokeDeviceTraitedDataDialog(device_traited_data)
        },
        displayToHumanAssetMetricFromCompleteDTd(data, parameter) {
            const output = JSON.parse(parameter)['output']

            if (data[output[0]][output[1]] && data[output[0]][output[1]][output[2]] !== null) {
                return data[output[0]][output[1]][output[2]]
            }
            return '-'
        },
    },
}
</script>

<style>
hr {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.network-icon {
    margin-right: 5px;
}
</style>
