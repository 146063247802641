<template>
    <div id="check-network">
        <v-container>
            <Header :text="$t('check_network.check_latest_device_data')" :title="$t('check_network.check_device_data')" />

            <div class="justify-center mt-2">
                <device-input
                    ref="DeviceInput"
                    :label="$t('check_network.device_number_to_check')"
                    :type="this.type_network"
                    v-on:getHistoricalTraitedData="getHistoricalTraitedData"
                    v-on:getNewHistoricalTraitedData="getNewHistoricalTraitedData"
                    v-on:reset="reset"
                    v-on:resetLocal="resetLocal"
                />

                <information-device-component
                    v-if="is_checking_network"
                    :asset="details_asset"
                    :client="details_client"
                    :device="details_device"
                    :loading_details="loading_details"
                    :module="details_module"
                    class="mt-2"
                />

                <template v-if="!buildingHeaders">
                    <list-check-network
                        :headers="headers"
                        :historical_traited_data="historical_traited_data"
                        :device="device"
                        :details_asset="details_asset"
                        :is_checking_network="is_checking_network"
                        class="mt-2"
                    />
                </template>

                <div class="text-center mt-2">
                    <a v-if="is_checking_network" class="link-reset font-weight-medium font-14 text-uppercase" @click="reset">
                        {{ $t('check_network.check_another_device') }}
                    </a>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import Header from '@/components/Header'
import ListCheckNetwork from '@/components/ListCheckNetwork'
import DeviceInput from '@/components/DeviceInput'
import InformationDeviceComponent from '../../components/InformationDeviceComponent'
import DeviceRepository from '../../repositories/DeviceRepository'
import AssetRepository from '@/repositories/AssetRepository'
import metricToDisplay from '@/mixin/metricToDisplay'
import ModuleRepository from '@/repositories/ModuleRepository'

export default {
    name: 'CheckNetwork',
    components: {
        InformationDeviceComponent,
        Header,
        ListCheckNetwork,
        DeviceInput,
        AssetRepository,
    },
    mixins: [metricToDisplay],
    data() {
        return {
            is_checking_network: false,
            historical_traited_data: [],
            is_loading: false,
            details_device: null,
            device:null,
            details_asset: null,
            loading_details: false,
            details_client: null,
            details_module: null,
        }
    },
    methods: {
        getHistoricalTraitedData(data, device_number) {
            this.historical_traited_data = data
            this.$store.commit('setAutoRefresh', setInterval(this.autoRefresh, 5000))
            this.is_checking_network = true
            this.getDeviceAndAssetDetails(device_number)
        },

        async getDeviceAndAssetDetails(device_number) {
            this.loading_details = true
            await Promise.all([this.getDetailsDevice(device_number), this.getAssetDetails(device_number), this.getDevice(device_number)])
            await this.buildHeadersByAsset(this.details_asset, this.details_device)
            this.loading_details = false
        },

        async getDetailsDevice(device_number) {
            this.details_device = null
            return DeviceRepository.getDetailsDevice(device_number)
                .then((success) => {
                    this.details_device = success.data.data
                    this.details_device.height *= 10
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        async getDevice(device_number){
            DeviceRepository.getDeviceByDeviceNumber(device_number)
                .then(async (success) => {
                    this.device = success.data.data

                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        async getAssetDetails(device_number) {
            this.details_asset = null
            return DeviceRepository.getDeviceAsset(device_number)
                .then(async (success) => {
                    this.details_asset = success.data.data.asset
                    this.details_client = success.data.data.client
                    if (this.details_asset.id_module) {
                        await this.fetchAssetModuleById()
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        fetchAssetModuleById() {
            ModuleRepository.getModuleById(this.details_asset.id_module)
                .then((success) => {
                    this.details_module = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },
        autoRefresh() {
            this.$refs.DeviceInput.getHistoricalTraitedData(true)
        },

        getNewHistoricalTraitedData(data) {
            this.historical_traited_data = data
        },

        resetLocal() {
            this.is_checking_network = false
            this.historical_traited_data = []
            this.$store.commit('clearIntervalAutoRefresh')
        },

        reset() {
            this.resetLocal()
            this.$refs.DeviceInput.resetLocal()
        },
    },
    mounted() {
        if (this.$route.params.device_number) {
            this.$refs.DeviceInput.selected_device.value = this.$route.params.device_number
            this.$refs.DeviceInput.checkDevice()
        }
    },
}
</script>

<style>
.link-reset {
    text-decoration: underline;
}
</style>
