import ModulesRepository from '@/repositories/ModuleRepository'

export default {
    data() {
        return {
            headers: [],
            buildingHeaders: true,
            requiredHeaders: [
                {
                    title: this.$t('list_check_network.quality_network'),
                    key: 'quality_network',
                    type: 'device',
                    display: 'required',
                },
                {
                    title: this.$t('list_check_network.trigger'),
                    key: 'declencheur',
                    type: 'device',
                    display: 'required',
                },
                {
                    title: this.$t('list_check_network.battery'),
                    key: 'batterie',
                    type: 'device',
                    display: 'required',
                },
            ],
            PnesHeaders: [
                {
                    title: this.$t('list_check_network.date_pnes'),
                    key: 'datePnes',
                    type: 'device',
                    display: 'optional',
                },
                {
                    title: this.$t('list_check_network.volume_pnes'),
                    key: 'volumePnes',
                    type: 'device',
                    display: 'optional',
                },
                {
                    title: this.$t('list_check_network.calendar'),
                    key: 'calendar',
                    type: 'device',
                    display: 'optional',
                },

                {
                    title: this.$t('list_check_network.modules_pnes'),
                    key: 'deviceRepeat',
                    type: 'device',
                    display: 'optional',
                },
            ],
            CR100Headers : [
            ],
        }
    },
    methods: {
        async buildHeadersByAsset(asset, device) {
            this.buildingHeaders = true
            this.headers = JSON.parse(JSON.stringify(this.requiredHeaders.concat(this.PnesHeaders) ))
            device.metrics.forEach((metric) => {
                if (
                    (metric.visible || metric.display_only_for_admin) &&
                    !this.requiredHeaders.find((item) => metric.metric.column_name_dtd === item.key)
                ) {
                    this.headers.push({
                        title: `${
                            metric.metric.column_name_dtd === 'quality_network'
                                ? this.$t('list_check_network.quality_network')
                                : `${metric.metric.label} ${metric.metric.unit ? `(${metric.metric.unit})` : ''}`
                        }`,
                        key: metric.metric.column_name_dtd,
                        type: 'device',
                        display: metric.display_only_for_admin ? 'admin' : 'all',
                    })
                }
            })

            if (asset && asset.id_module) {
                await ModulesRepository.getModuleById(asset.id_module)
                    .then((success) => {
                        let tempHeader = []
                        if (success.data.data.default_metrics_configuration.metrics) {
                            success.data.data.default_metrics_configuration.metrics.forEach((metric) => {
                                if (metric.is_visible) {
                                    tempHeader.push({
                                        title: `${metric.label} ${metric.unit ? `(${metric.unit})` : ''}`,
                                        key: metric.parameters,
                                        type: 'asset',
                                        display: 'all',
                                    })
                                }
                            })
                        }

                        this.headers.splice(1, 0, ...tempHeader)
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
            }

            this.buildingHeaders = false
        },
    },
}
