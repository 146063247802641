import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { IDevice } from '@/models/IDevice'
import { IDownlink } from '@/models/IDownlink'
import { IPaginationResponse } from '@/models/IPaginationResponse'
import { IDeviceReferentialKpi } from '@/models/IDeviceReferentialKpi'

const resource = '/api/v1/device'

export default {
    getHistoricalTraitedData(device_number, nb_statement): Promise<AxiosResponse<IServerResponse<Array<IDeviceTraitedData>>>> {
        return axios.get(`${resource}/${device_number}/historical_traited_data?limit=${nb_statement}&datatable=true`)
    },

    getDeviceByDeviceNumber(device_number: string): Promise<AxiosResponse<IServerResponse<IDevice>>> {
        return axios.get(`${resource}/${device_number}`)
    },

    getDeviceByIdUserAdminAndIsInStockDatatable(isInStock: number, search: string, pagination = ''): Promise<AxiosResponse<IServerResponse<IDevice[]>>> {
        return axios.get(`${resource}/user_admin/datatable?is_in_stock=${isInStock}&search=${search}&${pagination}`)
    },

    getAllDeviceByIdUserAdminAndIsInStockDatatable(search: string, orderBy = '', offset: number, limit: number): Promise<AxiosResponse<IServerResponse<IDevice[]>>> {
        return axios.get(`${resource}/user_admin/all_affected/datatable?&search=${search}&orders=${orderBy}&limit=${limit}&offset=${offset}`)
    },

    getDetailsDevice(device_number): Promise<AxiosResponse<IServerResponse<Array<IDeviceTraitedData>>>> {
        return axios.get(`${resource}/${device_number}`)
    },

    getDeviceDownlinkDatatable(idDevice: number, withHistory: boolean): Promise<AxiosResponse<IServerResponse<IPaginationResponse<IDownlink[]>>>> {
        return axios.get(`${resource}/${idDevice}/downlink/datatable?is_done=${withHistory}`)
    },

    checkDeviceToOrder(device_number): Promise<AxiosResponse<IServerResponse<IDeviceToOrder>>> {
        return axios.get(`${resource}/${device_number}/is_orderable`)
    },

    orderDevice(
        devices_number: string[],
        id_professional: number,
        id_contract_device: number,
    ): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post('/api/v1/commande/devices', {
            devices_number: devices_number,
            id_professional: id_professional,
            id_contract_device: id_contract_device,
        })
    },

    checkDeviceToReturn(device_number): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`${resource}/${device_number}/is_returnable`)
    },

    returnDevice(
        device_number_to_return: string,
        sent_to_maintenance: boolean,
        is_raz_device: boolean,
        return_reason: string,
        replace_device: boolean,
        new_device_number: string,
        id_contrat_device: string,
        keep_contract: boolean,
        migrate_data: boolean,
    ): Promise<AxiosResponse<IServerResponse<null>>> {
        let params = {
            device_number_to_return: device_number_to_return,
            sent_to_maintenance: sent_to_maintenance,
            replace_device: replace_device,
        }

        if (sent_to_maintenance) {
            params['is_raz_device'] = is_raz_device
            params['return_reason'] = return_reason
        }
        if (replace_device) {
            params['new_device_number'] = new_device_number
            params['migrate_data'] = migrate_data
        }
        if (replace_device && !keep_contract && id_contrat_device !== '') {
            params['id_contrat_device'] = id_contrat_device
        }

        return axios.post('/api/v1/return/device', params)
    },

    getDeviceAsset(device_number): Promise<AxiosResponse<IServerResponse<IAssetDevice>>> {
        return axios.get(`${resource}/${device_number}/asset`)
    },

    updateDevice(deviceNumber: string, noteAdmin: string): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${deviceNumber}`, { note_admin: noteAdmin })
    },

    postResetDevice(idDevice: number): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/${idDevice}/reset`);
    },

    postMassResetEmittingInStockDevices(): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/user_admin/is_in_stock/emitting/reset_all`);
    },

    postInstallNetworkDevice(networkDeviceNumber: number, idDomicile: number) {
        return axios.get(`${resource}/network/${networkDeviceNumber}/site/${idDomicile}`)
    },

    postSendSMSToPnes(sms): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/pnes/send_sms`, sms)
    },

    getLastDecodePnesPayload(id_device: number): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.get(`${resource}/pnes/${id_device}/payload/decode/last`)
    },

    postEncodePnesPayload(sms): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/pnes/payload/encode`, sms)
    },

    getNbDevicesByDevicesReferential(): Promise<AxiosResponse<IServerResponse<Array<IDeviceReferentialKpi>>>> {
        return axios.get(`${resource}/device_referentials/kpi`)
    },

    putAutoAssociateDevice(device): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/user_admin/auto_associate`, { id_device : device.id_device })
    }
}
