














import Vue from 'vue';
export default  Vue.extend(
    {
    name: 'ListItemRow',
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        showNull: {
            type: Boolean,
            default: true,
        },
        mkey: {
            type: String,
            required: true,
        }
    },
    computed: {
        display() {
            return this.showNull || (this.value !== '-' && this.value === '');
        },

    }
})
